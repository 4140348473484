import React from "react"

function OhmConnect() {
  return (
    <img
      src="/homepage/projects/ohmconnect.svg"
      style={{ height: "27px" }}
      alt="ohm-connect-project"
      loading="lazy"
    />
  )
}

function Icon_1() {
  return (
    <img
      src="/ohmConnect/teamIcons/icon-1.svg"
      alt="ohm-connect-hover"
      loading="lazy"
    />
  )
}
function Icon_2() {
  return (
    <img
      src="/ohmConnect/teamIcons/icon-2.svg"
      alt="ohm-connect-two"
      loading="lazy"
    />
  )
}

function Icon_3() {
  return (
    <img
      src="/ohmConnect/teamIcons/icon-3.svg"
      alt="oc-member-three"
      loading="lazy"
    />
  )
}
function Icon_4() {
  return (
    <img
      src="/ohmConnect/teamIcons/icon-4.svg"
      alt="oc-member-four"
      loading="lazy"
    />
  )
}
function Icon_5() {
  return (
    <img
      src="/ohmConnect/teamIcons/icon-5.svg"
      alt="oc-member-five"
      loading="lazy"
    />
  )
}

function Tech_1() {
  return <img src="/ohmConnect/Tech/tech-1.png" alt="tech-one" loading="lazy" />
}
function Tech_2() {
  return <img src="/ohmConnect/Tech/tech-2.png" alt="tech-two" loading="lazy" />
}

function Tech_3() {
  return (
    <img src="/ohmConnect/Tech/tech-3.png" alt="tech-three" loading="lazy" />
  )
}

function Tech_4() {
  return (
    <img src="/ohmConnect/Tech/tech-4.png" alt="tech-four" loading="lazy" />
  )
}

function Tech_5() {
  return (
    <img src="/ohmConnect/Tech/tech-5.png" alt="tech-five" loading="lazy" />
  )
}

function Tech_6() {
  return <img src="/ohmConnect/Tech/tech-6.png" alt="tech-six" loading="lazy" />
}

function Tech_7() {
  return (
    <img src="/ohmConnect/Tech/tech-7.png" alt="tech-seven" loading="lazy" />
  )
}

function Tech_8() {
  return (
    <img src="/ohmConnect/Tech/tech-8.png" alt="tech-eight" loading="lazy" />
  )
}
function Tech_9() {
  return (
    <img src="/ohmConnect/Tech/tech-9.png" alt="tech-nine" loading="lazy" />
  )
}
function Tech_10() {
  return (
    <img src="/ohmConnect/Tech/tech-10.png" alt="tech-ten" loading="lazy" />
  )
}
function Tech_11() {
  return <img src="/ohmConnect/Tech/tech-11.png" alt="tech-11" loading="lazy" />
}

function Tech_12() {
  return <img src="/ohmConnect/Tech/tech-12.png" alt="tech-12" loading="lazy" />
}

function Tech_13() {
  return <img src="/ohmConnect/Tech/tech-13.png" alt="tech-13" loading="lazy" />
}

function Tech_14() {
  return <img src="/ohmConnect/Tech/tech-14.png" alt="tech-14" loading="lazy" />
}
function Tech_15() {
  return <img src="/ohmConnect/Tech/tech-15.png" alt="tech-15" loading="lazy" />
}

function Tech_16() {
  return <img src="/ohmConnect/Tech/tech-16.png" alt="tech-16" loading="lazy" />
}
function Tech_17() {
  return <img src="/ohmConnect/Tech/tech-17.png" alt="tech-17" loading="lazy" />
}

function Tech_18() {
  return <img src="/ohmConnect/Tech/tech-18.png" alt="tech-18" loading="lazy" />
}

export {
  OhmConnect,
  Icon_1,
  Icon_2,
  Icon_3,
  Icon_4,
  Icon_5,
  Tech_1,
  Tech_2,
  Tech_3,
  Tech_4,
  Tech_5,
  Tech_6,
  Tech_7,
  Tech_8,
  Tech_9,
  Tech_10,
  Tech_11,
  Tech_12,
  Tech_13,
  Tech_14,
  Tech_15,
  Tech_16,
  Tech_17,
  Tech_18,
}
