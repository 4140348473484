import React, { useEffect } from "react"
import Layout from "../../components/Layout"
 
import OhmConnectPage from "../../components/OhmConnect2"
export default function OhmConnect() {
  

  

  return (
    <>
      <Layout>
        <OhmConnectPage />
      </Layout>
      
    </>
  )
}
